@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?yqqb9f');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?yqqb9f#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?yqqb9f') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?yqqb9f') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?yqqb9f##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-log-out {
  &:before {
    content: $icon-log-out; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-message-lines {
  &:before {
    content: $icon-message-lines;     
    color: #5c5c5c;
  }
}
.icon-phone-off {
  &:before {
    content: $icon-phone-off; 
  }
}
.icon-phone-missed {
  &:before {
    content: $icon-phone-missed; 
  }
}
.icon-phone-forwarded {
  &:before {
    content: $icon-phone-forwarded; 
  }
}
.icon-phone-call {
  &:before {
    content: $icon-phone-call; 
  }
}
.icon-phone-incoming {
  &:before {
    content: $icon-phone-incoming; 
  }
}
.icon-phone-outgoing {
  &:before {
    content: $icon-phone-outgoing; 
  }
}
.icon-trash {
  &:before {
    content: $icon-trash; 
  }
}
.icon-external-link {
  &:before {
    content: $icon-external-link; 
  }
}
.icon-notes {
  &:before {
    content: $icon-notes; 
  }
}
.icon-changes-drop {
  &:before {
    content: $icon-changes-drop;     
    color: #d6002a;
  }
}
.icon-changes-rise {
  &:before {
    content: $icon-changes-rise;     
    color: #090;
  }
}
.icon-navigation {
  &:before {
    content: $icon-navigation; 
  }
}
.icon-copy {
  &:before {
    content: $icon-copy; 
  }
}
.icon-record-type {
  &:before {
    content: $icon-record-type; 
  }
}
.icon-coins {
  &:before {
    content: $icon-coins;     
    color: #5c5c5c;
  }
}
.icon-logo-Sign .path1 {
  &:before {
    content: $icon-logo-Sign-path1;  
    color: rgb(214, 0, 42);
  }
}
.icon-logo-Sign .path2 {
  &:before {
    content: $icon-logo-Sign-path2;  
    margin-left: -1.6669921875em;  
    color: rgb(0, 0, 0);
  }
}
.icon-add {
  &:before {
    content: $icon-add; 
  }
}
.icon-add-log {
  &:before {
    content: $icon-add-log; 
  }
}
.icon-back {
  &:before {
    content: $icon-back; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-filter {
  &:before {
    content: $icon-filter; 
  }
}
.icon-Info {
  &:before {
    content: $icon-Info; 
  }
}
.icon-lists {
  &:before {
    content: $icon-lists; 
  }
}
.icon-profile {
  &:before {
    content: $icon-profile; 
  }
}
.icon-sorting2 {
  &:before {
    content: $icon-sorting2; 
  }
}
.icon-sorting1 {
  &:before {
    content: $icon-sorting1; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-success {
  &:before {
    content: $icon-success; 
  }
}
.icon-profile1 {
  &:before {
    content: $icon-profile1; 
  }
}
.icon-rightArrow {
  &:before {
    content: $icon-rightArrow; 
  }
}
.icon-leftArrow {
  &:before {
    content: $icon-leftArrow; 
  }
}
.icon-fingerprint {
  &:before {
    content: $icon-fingerprint; 
  }
}
.icon-filled-down {
  &:before {
    content: $icon-filled-down; 
  }
}
.icon-filled-up {
  &:before {
    content: $icon-filled-up; 
  }
}
.icon-clearText {
  &:before {
    content: $icon-clearText; 
  }
}
.icon-upArrow {
  &:before {
    content: $icon-upArrow; 
  }
}
.icon-downArrow {
  &:before {
    content: $icon-downArrow; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-home {
  &:before {
    content: $icon-home; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-attention {
  &:before {
    content: $icon-attention; 
  }
}
.icon-recentSearchTime {
  &:before {
    content: $icon-recentSearchTime; 
  }
}
.icon-holdings {
  &:before {
    content: $icon-holdings; 
  }
}
.icon-accounts {
  &:before {
    content: $icon-accounts; 
  }
}
.icon-placeholder {
  &:before {
    content: $icon-placeholder; 
  }
}
.icon-dots-three-vertical {
  &:before {
    content: $icon-dots-three-vertical; 
  }
}
.icon-dots-three-horizontal {
  &:before {
    content: $icon-dots-three-horizontal; 
  }
}
.icon-location {
  &:before {
    content: $icon-location; 
  }
}
.icon-star-empty {
  &:before {
    content: $icon-star-empty; 
  }
}
.icon-star-full {
  &:before {
    content: $icon-star-full; 
  }
}
.icon-checkbox-unchecked {
  &:before {
    content: $icon-checkbox-unchecked; 
  }
}

