@import "./colors";

/* Cherry/Headings/H1 (medium) */
@mixin headings-1 {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: $black;
}

/* Cherry/Headings/H2 (medium) */
@mixin headings-2 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: $black;
}

/* Cherry/Headings/H3 (medium) */
@mixin headings-3 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;

  color: $black;
}

/* Cherry/Label/Small (med) */
@mixin body-small {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;

  color: $black;
}

/* Cherry/Body/Medium (reg) */
@mixin body-medium {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: $gray;
}

/* Cherry/Body/Large (reg) */
@mixin body-large {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: $black;
}

/* Cherry/Label/Large (medium); */
@mixin label-large {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: $black;
}
