.c-modal-body {
  padding: 0 !important;
}

.ticker-container {
  .c-checkbox.c-checkbox-lg {
    display: flex;
    height: auto!important;
  }

  .c-checkbox.c-checkbox-lg label::before {
    min-width: 24px;
    min-height: 24px;
    margin-right: 14px!important;
  }
}

.comment-textarea-container {
  .c-input {
    border: 1px solid white!important;
    font-size: 16px;
  }
}

