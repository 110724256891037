@font-face {
  font-family: Roboto-Black;
  src: url("../../assets/fonts/Roboto-Black.ttf") format("truetype");
}
@font-face {
  font-family: Roboto-BlackItalic;
  src: url("../../assets/fonts/Roboto-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: Roboto-Bold;
  src: url("../../assets/fonts/Roboto-Bold.ttf") format("truetype");
}
@font-face {
  font-family: Roboto-BoldItalic;
  src: url("../../assets/fonts/Roboto-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: Roboto-Italic;
  src: url("../../assets/fonts/Roboto-Italic.ttf") format("truetype");
}
@font-face {
  font-family: Roboto-Light;
  src: url("../../assets/fonts/Roboto-Light.ttf") format("truetype");
}
@font-face {
  font-family: Roboto-LightItalic;
  src: url("../../assets/fonts/Roboto-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: Roboto-Medium;
  src: url("../../assets/fonts/Roboto-Medium.ttf") format("truetype");
}
@font-face {
  font-family: Roboto-MediumItalic;
  src: url("../../assets/fonts/Roboto-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: Roboto-Regular;
  src: url("../../assets/fonts/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: Roboto-Thin;
  src: url("../../assets/fonts/Roboto-Thin.ttf") format("truetype");
}
@font-face {
  font-family: Roboto-ThinItalic;
  src: url("../../assets/fonts/Roboto-ThinItalic.ttf") format("truetype");
}
/* Cherry/Headings/H1 (medium) */
/* Cherry/Headings/H2 (medium) */
/* Cherry/Headings/H3 (medium) */
/* Cherry/Label/Small (med) */
/* Cherry/Body/Medium (reg) */
/* Cherry/Body/Large (reg) */
/* Cherry/Label/Large (medium); */
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #F8F8F8;
  font-family: Roboto-Regular, sans-serif !important;
}

body::-webkit-scrollbar {
  width: 0 !important;
}

i {
  cursor: pointer;
}

a {
  text-decoration: none;
  display: inline-block;
  color: unset;
}

a:visited {
  color: unset;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  padding: 0;
  margin: 0;
}

.main-padding {
  padding: 16px;
}

.main-padding-sides-only {
  padding: 0 16px;
}

.main-padding-vertical-only {
  padding: 16px 0;
}

.main-margin-small-top {
  margin-top: 8px;
}

.main-margin-small-bottom {
  margin-bottom: 8px;
}

.main-margin-small-left {
  margin-left: 8px;
}

.main-margin-small-right {
  margin-right: 8px;
}

.main-margin-top {
  margin-top: 16px;
}

.main-margin-bottom {
  margin-bottom: 16px;
}

.main-margin-left {
  margin-left: 16px;
}

.main-margin-right {
  margin-left: 16px;
}

.ellipse-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-center {
  text-align: center;
}

.overflow-hidden {
  overflow: hidden;
}

.loader-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.h-full {
  height: 100%;
}

.modal-alert .c-modal-content {
  border-radius: 4px;
  width: 90%;
}

.modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: #FFFFFF;
}

.empty-result {
  text-align: center;
  margin-top: 20px;
}

.footer-buttons-set {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px 20px;
  width: 100%;
  background-color: #FFFFFF;
  gap: 8px;
  -webkit-box-shadow: 0px -2px 4px -1.1875px rgba(33, 37, 41, 0.1);
  -moz-box-shadow: 0px -2px 4px -1.1875px rgba(33, 37, 41, 0.1);
  box-shadow: 0px -2px 4px -1.1875px rgba(33, 37, 41, 0.1);
}

.button {
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #000000;
  border-radius: 2px !important;
}
.button-neutral {
  background-color: #EEEEEE !important;
  border: 1px solid #CCCCCC !important;
}
.button-primary {
  background-color: #006D89 !important;
  color: #FFFFFF !important;
}
.button-primary[disabled] {
  background-color: #EEEEEE !important;
  color: #999999 !important;
}

.form-button {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  font-family: Roboto-Regular !important;
  letter-spacing: 0 !important;
  border-radius: 2px !important;
  border: 0 !important;
}

.c-modal-body {
  padding: 0 !important;
}

.ticker-container .c-checkbox.c-checkbox-lg {
  display: flex;
  height: auto !important;
}
.ticker-container .c-checkbox.c-checkbox-lg label::before {
  min-width: 24px;
  min-height: 24px;
  margin-right: 14px !important;
}

.comment-textarea-container .c-input {
  border: 1px solid white !important;
  font-size: 16px;
}