@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin box-sizing($value) {
  -webkit-box-sizing: $value;
  -moz-box-sizing: $value;
  box-sizing: $value;
}

@mixin box-shadow($value...) {
  -webkit-box-shadow: $value;
  -moz-box-shadow: $value;
  box-shadow: $value;
}

@mixin animation($value...) {
  -webkit-animation: $value;
  animation: $value;
}

@mixin transform($value...) {
  -ms-transform: $value;
  -webkit-transform: $value;
  transform: $value;
}

@mixin transition($args...) {
  -webkit-transition: ($args);
  -ms-transition: ($args);
  -moz-transition: ($args);
  transition: ($args);
}

@mixin align-items($value) {
  -webkit-align-items: $value;
  align-items: $value;
}

@mixin align-self($value) {
  -webkit-align-self: $value;
  align-self: $value;
}

@mixin justify-content($value) {
  -webkit-justify-content: $value;
  justify-content: $value;
}

@mixin justify-self($value) {
  -webkit-justify-self: $value;
  justify-self: $value;
}

@mixin flex-wrap($value) {
  -webkit-flex-wrap: $value;
  flex-wrap: $value;
}

@mixin appearance($value) {
  -webkit-appearance: $value;
  -moz-appearance: $value;
  appearance: $value;
}

@mixin filter($value) {
  -webkit-filter: $value;
  filter: $value;
}

@mixin linear-gradient($args...) {
  background-image: -o-linear-gradient($args);
  background-image: -moz-linear-gradient($args);
  background-image: -webkit-linear-gradient($args);
  background-image: linear-gradient($args);
}

@mixin text-overflow($value) {
  -ms-text-overflow: $value;
  text-overflow: $value;
}

@mixin multiline-ellipsis-text($value) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $value;
  line-clamp: $value;
  -webkit-box-orient: vertical;
}

@mixin position-sticky() {
  position: sticky;
  position: -webkit-sticky;
}


