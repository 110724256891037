$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-log-out: "\e932";
$icon-phone: "\e92b";
$icon-message-lines: "\e926";
$icon-phone-off: "\e920";
$icon-phone-missed: "\e921";
$icon-phone-forwarded: "\e922";
$icon-phone-call: "\e923";
$icon-phone-incoming: "\e924";
$icon-phone-outgoing: "\e925";
$icon-trash: "\e92f";
$icon-external-link: "\e931";
$icon-notes: "\e930";
$icon-changes-drop: "\e92d";
$icon-changes-rise: "\e92e";
$icon-navigation: "\e92a";
$icon-copy: "\e92c";
$icon-record-type: "\e91f";
$icon-coins: "\e927";
$icon-logo-Sign-path1: "\e91d";
$icon-logo-Sign-path2: "\e91e";
$icon-add: "\e913";
$icon-add-log: "\e914";
$icon-back: "\e915";
$icon-close: "\e916";
$icon-filter: "\e917";
$icon-Info: "\e918";
$icon-lists: "\e919";
$icon-profile: "\e91a";
$icon-sorting2: "\e907";
$icon-sorting1: "\e908";
$icon-search: "\e909";
$icon-success: "\e90a";
$icon-profile1: "\e90b";
$icon-rightArrow: "\e90c";
$icon-leftArrow: "\e90d";
$icon-fingerprint: "\e90e";
$icon-filled-down: "\e91b";
$icon-filled-up: "\e91c";
$icon-clearText: "\e904";
$icon-upArrow: "\e90f";
$icon-downArrow: "\e902";
$icon-check: "\e905";
$icon-home: "\e910";
$icon-calendar: "\e906";
$icon-attention: "\e903";
$icon-recentSearchTime: "\e911";
$icon-holdings: "\e912";
$icon-accounts: "\e901";
$icon-placeholder: "\e900";
$icon-dots-three-vertical: "\e928";
$icon-dots-three-horizontal: "\e929";
$icon-location: "\e947";
$icon-star-empty: "\e9d7";
$icon-star-full: "\e9d9";
$icon-checkbox-unchecked: "\ea53";

