$main-color: #F8F8F8;
$gray: #5C5C5C;
$shade-gray: #999999;
$gray-dark: #1A1A1A;
$gray-middle-dark: #CCCCCC;
$light-gray: #E9ECEF;
$middle-light-gray: #EEEEEE;
$neutral-grey: #E0E0E0;
$white: #FFFFFF;
$green: #008080;
$accent-green: #D8EDED;
$green-dark: #006D89;
$black: #000000;
$red: #D6002A;
$blue-light: #5D8AA8;
$blue: #4F7FBC;
$blue-middle-dark: #006D89;
$blue-dark: #00495B;
